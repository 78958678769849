import { useCallback, useContext, useEffect, useState } from "react";
import ActivityService from "../../../services/ActivityService";
import ClientService from "../../../services/ClientService";
import CompanyService from "../../../services/CompanyService";
import GroupService from "../../../services/GroupService";
import ToastContext from "../../shared/bootstrap/Toast";
import Dropdown from "../../shared/components/Dropdown";
import { AutocompleteSelect, HasValidationEditor, HasValidationEditorProps, TagsDropdownEditor, TextAreaBigEditor, TextEditor } from "../../shared/components/Editors";
import FloatingPanelService from "../../shared/FloatingPanel";
import { TranslationService } from "../../../services/TranslationService";
import { FloatingPanelFooter } from "../../shared/components/FloatingPanelFooter";
import TagService from "../../../services/TagService";
import { Client } from "../entities/ClientGetResponse";
import TooltipComponent from "../../shared/TooltipComponent";

class SMSEdit {
    groupId?: string;
    telephone?: string;
    message?: string;
    activityTypeId?: number;
    tags?: string;

    constructor(person: Client, activityTypeId: number | undefined) {
        this.activityTypeId = activityTypeId ?? CompanyService.getActivityTypes().find(x => x.SMS && x.SMSDefault)?.ActivityTypeID;
        const field = CompanyService.getCompanyAuth()?.DefaultSMSField;
        if (field) {
            this.telephone = field === -1 ? person.Phone : person.additionals.find(x => x.id === CompanyService.getCompanyAuth()!.DefaultSMSField)?.value;
        }
    }
}

export const SMSCompose = ({ person, onSubmit }: { person: Client, onSubmit?: () => void }) => {
    const activityTypeOptions = CompanyService.getActivityTypes().filter(x => x.SMS).map(x => ({ value: x.ActivityTypeID, text: x.ActivityTypeName }));
    const [SMS, setSMS] = useState(new SMSEdit(person, CompanyService.getActivityTypes().find(x => x.SMS && x.SMSDefault)?.ActivityTypeID));
    const [isSending, setIsSending] = useState(false);
    const [messageCharCount, setMessageCharCount] = useState(0);
    const { translate } = TranslationService;
    const { showToast } = useContext(ToastContext);
    const validationFunctionArray: (() => boolean)[] = [];
    const maxCharCount = 160;

    const setSMSField = useCallback((property: string) => (value: number | string | undefined) => {
        setSMS(SMS => ({ ...SMS, [property]: value }));
    }, [setSMS]);

    useEffect(() => {
        ClientService.get(person.PersonId).then(x => {
            if (x instanceof Error) { return; }
            setSMSField("telephone")(x.item.Phone);
        });
    }, [person.PersonId, setSMSField]);

    const canSubmit = () => validationFunctionArray.filter(x => !x()).length === 0;
    const submit = async () => {
        if (isSending || !canSubmit()) { return; }
        setIsSending(true);

        {
            const result = await TagService.createMissingTags(SMS.tags);
            if (result instanceof Error) {
                showToast(translate.ErrorProcessingRequest, undefined, "danger");
                setIsSending(false);
                return;
            }
            setSMS(SMS => ({ ...SMS, tags: result }));
            SMS.tags = result;
        }

        const result = await ActivityService.setSMS({
            personid: person.PersonId,
            content: SMS.message ?? "",
            phone: SMS.telephone ?? "",
            activitytypeid: SMS.activityTypeId ? SMS.activityTypeId : undefined,
            tagids: SMS.tags,
            groupid: SMS.groupId,
        });
        if (result instanceof Error) {
            showToast(translate.ErrorProcessingRequest, undefined, "danger");
        }
        else {
            showToast(translate.Sent, undefined, "success");
            onSubmit && onSubmit();
            setSMS(new SMSEdit(person, activityTypeOptions.find(x => x)?.value));
        }
        setIsSending(false);
        FloatingPanelService.hidePanel();
    };

    return (
        <>
            <div className="tabBody">
                <div className="row align-items-center mb-2 g-0 ">
                    <div className="col-2 pe-2">
                        <label className="form-label mb-0">{translate.Telephone}:</label>
                    </div>
                    <div className="col-10">
                        <HasValidationEditor {...new HasValidationEditorProps(
                            TextEditor,
                            setSMSField("telephone"),
                            <p className='text-danger mb-0'>{translate.RequiredField}</p>,
                            undefined,
                            SMS.telephone,
                            validationFunctionArray
                        )} />
                    </div>
                </div>

                <div className="d-flex input-column">
                    <label className="form-label">{translate.Message}:</label>
                    <HasValidationEditor
                        {...new HasValidationEditorProps(
                            TextAreaBigEditor,
                            message => { setMessageCharCount(message?.length ?? 0); setSMSField("message")(message); },
                            <p className='text-danger mb-0'>{translate.SMSMessageInvalidText.replace("{maxCharCount}", maxCharCount.toString())}</p>,
                            message => !!message && message.length <= maxCharCount,
                            undefined,
                            validationFunctionArray,
                        )} />
                    <label className={"form-label" + (messageCharCount > maxCharCount ? " text-danger" : "")}>
                        {messageCharCount}/{maxCharCount}
                    </label>
                </div>

                <div className="col-12">
                    <hr />
                </div>

                {Boolean(CompanyService.getTags()?.length) &&
                    <div className="d-flex align-items-center mb-2">
                        <TooltipComponent title={TranslationService.translate.Tags}>
                            <i className="fa fa-tags text-secondary"></i>
                        </TooltipComponent>
                        <div className="ps-2 col">
                            <TagsDropdownEditor onChange={setSMSField("tags")} />
                        </div>
                    </div>}

                <div className="d-flex align-items-center mb-2">
                    <div className="col-2">
                        <label className="form-label mb-0">{translate.ActivityType}:</label></div>
                    <div className="col">
                        <Dropdown onChange={setSMSField("activityTypeId")} items={activityTypeOptions} optionLabel={translate.None} defaultValue={SMS.activityTypeId} />
                    </div>
                </div>
                {CompanyService.getGroupName()?.length > 0 &&
                    <div className="d-flex align-items-center mb-2">
                        <div className="col-2">
                            <label className="form-label mb-0">{CompanyService.getGroupName()}:</label></div>
                        <div className="col">
                            <AutocompleteSelect loadOptions={value => GroupService.getGroupSelectOptions(person.PersonId.toString(), value)} onChange={x => setSMSField("groupId")(x?.value)} />
                        </div>
                    </div>}






            </div >
            <FloatingPanelFooter>
                <button className='btn btn-primary me-3' onClick={submit}>
                    {translate.Send}
                    {isSending && <i className="fas fa-spinner-third fa-spin third ms-2"></i>}
                </button>
            </FloatingPanelFooter>
        </>
    );
};