import intizaLogoPng from "../../assets/img/intizaLogoWhite.png";
import { Link } from "react-router-dom";
import { TranslationService } from "../../services/TranslationService";
import ModalService from "./bootstrap/Modal";
import { Cast } from "../../utils/Utils";
import Label from "./form/Label";
import { RequiredManager, ValidationMessage } from "./RequieredManager";

const Footer = () => {
    const year = new Date().getFullYear();
    const onInformationClick = () => {
        ModalService.showDefaultModal({
            hideClose: true,
            title: TranslationService.translate.ContactInfo,
            message: () => {
                const onRef = () => {
                    const script = document.createElement("script");
                    script.src = "https://js.hsforms.net/forms/v2.js?pre=1";
                    document.body.appendChild(script);
                    script.addEventListener("load", () => {
                        const win_hb = Cast<{ hbspt: { forms: { create: (value: Record<string, string>) => void } } }>(window);
                        if (win_hb.hbspt) {
                            win_hb.hbspt.forms.create({
                                portalId: "22073597",
                                formId: "82b36f3b-a008-48c3-9bd4-460d507e9b33",
                                target: "#hubspotForm",
                                region: "na1",
                            });
                        }
                    });
                };
                return (<>
                    <div ref={onRef} id="hubspotForm">
                    </div>
                </>);
            },
        });
    };

    const OpenTechnicalSupportModal = () => {
        const requiredManager = new RequiredManager();
        const message = {
            name: "",
            email: "",
            company: "",
            country: "",
            phone: "",
            message: "",
        };
        const onSubmit = () => {
            if (!requiredManager.validate()) {
                return;
            }
            ModalService.hideModal();
        };
        ModalService.showDefaultModal({
            title: TranslationService.translate.ContactSupport,
            hideClose: true,
            message: () => {
                const change = <T extends keyof typeof message,>(key: T) => (value: string) => message[key] = value;
                const nameChange = requiredManager.makeRequiredWithId(change("name"), "name");
                const emailChange = requiredManager.makeRequiredWithId(change("email"), "email");
                const countryChange = requiredManager.makeRequiredWithId(change("country"), "country");
                const phoneChange = requiredManager.makeRequiredWithId(change("phone"), "phone");
                const messageChange = requiredManager.makeRequiredWithId(change("message"), "message");
                return (<>
                    <div className="">
                        <Label label={TranslationService.translate.Name}>
                            <input className="form-control" onChange={(e) => nameChange(e.target.value)} />
                            <ValidationMessage onChange={nameChange} />
                        </Label>
                        <Label label={TranslationService.translate.Email}>
                            <input className="form-control" onChange={(e) => emailChange(e.target.value)} />
                            <ValidationMessage onChange={emailChange} />
                        </Label>
                        <Label label={TranslationService.translate.Company}>
                            <input className="form-control" onChange={(e) => change("company")(e.target.value)} />
                        </Label>
                        <Label label={TranslationService.translate.Country}>
                            <input className="form-control" onChange={(e) => countryChange(e.target.value)} />
                            <ValidationMessage onChange={countryChange} />
                        </Label>
                        <Label label={TranslationService.translate.Phone}>
                            <input className="form-control" onChange={(e) => phoneChange(e.target.value)} />
                            <ValidationMessage onChange={phoneChange} />
                        </Label>
                        <Label label={TranslationService.translate.Message}>
                            <textarea className="form-control" onChange={(e) => messageChange(e.target.value)} />
                            <ValidationMessage onChange={messageChange} />
                        </Label>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{TranslationService.translate.Close}</button>
                        <button type="button" className={"btn btn-primary"} onClick={onSubmit}>{TranslationService.translate.Send}</button>
                    </div>
                </>);
            }
        });
    };

    return (
        <footer>
            <div className="container-fluid">
                <div className="row justify-content-between align-items-center">
                    <div className="col-md-3">
                        <Link to={"/"} className="navbar-brand">
                            <img src={intizaLogoPng} alt="Intiza" />
                        </Link>
                    </div>
                    <div className="col-md-6">
                        <ul style={{ display: "flex" }}>
                            <li><a href="/" onClick={(e) => { e.preventDefault(); OpenTechnicalSupportModal(); }}>{TranslationService.translate.ContactSupport}</a></li>
                            <li><a href="https://ayuda.intiza.com" target="_blank" rel="noreferrer">{TranslationService.translate.Help}</a></li>
                            <li><a href="/#" onClick={(e) => { e.preventDefault(); onInformationClick(); }}>{TranslationService.translate.ContactInfo}</a></li>
                        </ul>
                    </div>
                    <div className="col-md-3 d-flex justify-content-end">
                        <div className="rrss" style={{ display: "flex" }}>
                            <a href="https://www.linkedin.com/company/intiza/" target="_blank" rel="noreferrer">
                                <i className="fa-brands fa-linkedin"></i>
                            </a>
                            <a href="https://x.com/IntizaSaaS" rel="noreferrer">
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 18 18">
                                    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="subfooter">
                        Copyright {year} | Intiza S.A. {TranslationService.translate.Rights}
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;