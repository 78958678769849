import React, { useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import CompanyService, { DataType, Entities } from "../../../services/CompanyService";
import InvoiceService from "../../../services/InvoiceService";
import { formatCurrency, formatDateShort, formatNumber, unbreak } from "../../../utils/FormatUtils";
import FloatingPanelService from "../../shared/FloatingPanel";
import { TranslationService } from "../../../services/TranslationService";
import TableContext from "../../task/TableContext";
import ClientDetailContext from "../ClientDetailContext";
import { InvoiceList } from "./entities/InvoiceListResponse";
import InvoiceDetail from "./InvoiceDetail";
import { InvoiceChangeStatusForm } from "./InvoiceChangeStatusForm";
import ModalService, { ModalParams } from "../../shared/bootstrap/Modal";
import { InvoiceEditStatus } from "./entities/InvoiceEditStatus";
import { ToastService } from "../../shared/bootstrap/Toast";
import { TableHeaderWithFieldId } from "../../shared/Table";
import { Cast, OptionalMap } from "../../../utils/Utils";
import { handleErrorWithToast } from "../../../utils/RequestUtils";
import { CacheEntity, StorageService } from "../../../services/StorageService";
import { percentFormatCollection } from "../../../utils/PercentCollection";
import { parseDate } from "../../../utils/ParseUtils";
import Select from "../../shared/components/Select";

const InvoiceTableItem = (onCheckboxChange?: (invoiceId: number, checked: boolean) => void, headers?: TableHeaderWithFieldId[]) =>
    function InvoiceTableItemImpl({ data }: { data: InvoiceList.Item }) {
        const specialCases = {
            "download": () => !data.HasFiles ? <td></td> : <td><i className="fas fa-fw fa-download pointer" onClick={(e) => { e.stopPropagation(); handleErrorWithToast(InvoiceService.downloadAllFilesFromInvoice(data.IOID)); }}></i></td>,
            "group": () => <td>{data.GroupID ? StorageService.getCache(CacheEntity.GroupName, data.GroupID) : ""}</td>
        };
        return (
            <ClientDetailContext.Consumer>
                {({ invoiceIds, invoiceAll, reloadExtraData }) =>
                    <TableContext.Consumer>
                        {({ reload }) => {
                            if (!headers) {
                                headers = InvoiceService.getTableHeaders();
                            }
                            return <tr key={data.IOID} style={{ cursor: "pointer" }} onClick={() => FloatingPanelService.showPanel(
                                {
                                    children: <InvoiceDetail key={data.IOID} invoiceId={data.IOID} reload={() => { reload(); reloadExtraData(); }} />,
                                    title: "",
                                    width: 750,
                                    height: 800,
                                }
                            )}>
                                {onCheckboxChange &&
                                    <td onClick={event => event.stopPropagation()} className="p-0">
                                        <div className="fixed-checkbox-left" >
                                            <input
                                                type="checkbox"
                                                className="checkbox-invoice"
                                                data-invoiceselected={data.IOID}
                                                defaultChecked={invoiceAll || invoiceIds.includes(data.IOID)}
                                                onChange={event => onCheckboxChange(data.IOID, (event.target as HTMLInputElement).checked)}
                                            />
                                        </div>
                                    </td>}
                                {headers.filter(x => x.fieldId !== "checkbox").map(header => <TableColumn key={header.fieldId} data={data} header={header} specialCases={specialCases} />)}
                            </tr>;
                        }}
                    </TableContext.Consumer>
                }
            </ClientDetailContext.Consumer>
        );
    };

export const TableColumn = <T,>({ data, header, specialCases = {} }: { data: InvoiceList.Item, header: TableHeaderWithFieldId, specialCases?: { [key: string]: undefined | ((x: T) => (undefined | React.ReactElement)) } }) => {
    const { translate, currentLanguage } = TranslationService;
    const { reload } = useContext(TableContext);
    const { reloadExtraData } = useContext(ClientDetailContext);
    const getValue = () => {
        if (header.key.startsWith("ioadditional.")) {
            return data.additionals.find(x => x.Id.toString() === header.fieldId)?.Value ??
                Cast<{ transactionadditionals?: typeof data.additionals }>(data).transactionadditionals?.find(x => x.Id.toString() === header.fieldId)?.Value;
        }
        return data[header.fieldId as keyof InvoiceList.Item];
    };

    const statusFields = useMemo(() => {
        return new Map(CompanyService.getIoStatus().map(status => {
            return [status.IOStatusID, CompanyService.getAdditionalDefinitionsFiltered(Entities.Invoice)
                .filter(x => x.IOStatusDependencies.filter(y => y.IOStatusID === status.IOStatusID).length > 0)];
        }));
    }, []);

    const negativeValueClass = CompanyService.getSetting("iosnegativeamountinred") ? "text-danger" : "";
    const value = getValue();
    const specialCase = specialCases[header.fieldId];
    if (specialCase) {
        const caseResult = specialCase(Cast<T>(data));
        if (caseResult) {
            return caseResult;
        }
    }


    switch (header.fieldId) {
        case "StatusIcon":
            return <td>{data.pending === 0 ? <i className="far fa-square-check"></i> : ""}</td>;
        case "dueDate":
            if (data.dueDays === 0) {
                return <td>{data.dueDate ? formatDateShort(new Date(data.dueDate)) : ""}</td>;
            }
            if (data.dueDays > 0) {
                return <td className="text-danger">{formatDateShort(new Date(data.dueDate))}<small className="ps-1">({data.dueDays} {translate.Days})</small></td>;
            }
            return <td>{unbreak(formatDateShort(new Date(data.dueDate)))}</td>;
        case "status": {
            const status = CompanyService.getIoStatus().find(x => x.IOStatusID === data.IOStatusID)?.Value ?? CompanyService.getNormalIOStatusName();
            if (CompanyService.canDo("editio")) {
                const onStatusSubmit = async (statusEdit: InvoiceEditStatus) => {
                    const result = await InvoiceService.statusChange({
                        invoiceIds: [data.IOID],
                        all: false,
                        filters: undefined,
                        personId: data.personId.toString(),
                        statusEdit
                    });
                    if (result instanceof Error) {
                        ToastService.showToast(translate.ErrorProcessingRequest, undefined, "danger");
                    }
                    else {
                        ToastService.showToast(translate.SavedChanges, undefined, "success");
                        if (result.refreshactivity) {
                            reloadExtraData();
                        }
                    }
                    ModalService.hideModal();
                    reload();
                };
                const statusAction = (statusId: number) => {
                    if (statusFields.get(statusId)?.length) {
                        const changeStatusModal = new ModalParams();
                        changeStatusModal.children = <InvoiceChangeStatusForm onSubmit={onStatusSubmit} personId={data.personId.toString()} defaultStatusId={statusId} defaultAdditionals={data.additionals} />;

                        changeStatusModal.title = translate.ChangeIOStatus;
                        changeStatusModal.closeClickingOutside = true;
                        return () => ModalService.showModal(changeStatusModal);
                    } else {
                        return () => onStatusSubmit({
                            additionals: [],
                            statusId: statusId,
                            userId: CompanyService.getUserid(),
                        });
                    }
                };

                const changeStatusModal = new ModalParams();
                changeStatusModal.children = <InvoiceChangeStatusForm onSubmit={onStatusSubmit} personId={data.personId.toString()} defaultStatusId={data.IOStatusID ?? 0} defaultAdditionals={data.additionals} />;

                changeStatusModal.title = translate.ChangeIOStatus;
                changeStatusModal.closeClickingOutside = true;
                const statuses = CompanyService.getIoStatus().map(x => ({ text: x.Value, value: statusAction(x.IOStatusID) }));

                return <td style={{ padding: 0, maxWidth: "unset", textAlign: "right" }} onClick={event => event.stopPropagation()}>
                    <Select options={statuses} onChange={x => x()} extraClass="invoice-table-select" value={statuses.find(x => x.text === status)?.value}/>
                </td>;
            }
            return <td>{unbreak(status)}</td>;
        }
        case "percentDue": {
            return <td className={"text-end "}>{percentFormatCollection(data.overdueAmount, data.amount)}</td>;
        }
        case "pending2": {
            const defaultCurrencyId = CompanyService.getDefaultCurrencyId();
            if (data.CurrencyID === defaultCurrencyId) {
                return <td className={"text-end"}></td>;
            }
            const currencies = CompanyService.getCurrencies();
            const rate = currencies.find(x => x.CurrencyId === data.CurrencyID)!.Rate;
            return <td className={"text-end " + (data.pending < 0 ? negativeValueClass : "")}>{formatCurrency(data.pending * rate, defaultCurrencyId, formatNumber)}</td>;
        }
        case "Name":
            return (<td className="is-link long-text">
                <span className="w-md">
                    <Link to={`/${currentLanguage}/client/details?id=${data.personId}`}>
                        {Cast<{ Fullname: string }>(data).Fullname}
                    </Link>
                </span>
            </td>);
    }
    if (value === undefined || value === null || value === "") {
        return <td></td>;
    }
    switch (header.type) {
        case DataType.Currency:
            return <td className={"text-end " + (parseFloat(value) < 0 ? negativeValueClass : "")}>{formatCurrency(parseFloat(value), data.CurrencyID, formatNumber)}</td>;
        case DataType.Number:
            return <td className={"text-end"}>{formatNumber(value)}</td>;
        case DataType.Date:
            return <td>{OptionalMap(parseDate(value), x => formatDateShort(x))}</td>;
        // return <td>{OptionalMap(parseDate(value), x => <TooltipComponent title={(x.getHours() | x.getMinutes()) ? formatDateTimeDigits(x) : ""}>{formatDateShort(x)}</TooltipComponent>)}</td>;
        case DataType.List: {
            const additionalId = header.fieldId;
            const additionalDefinition = CompanyService.getAdditionalDefinitions()
                .find(x => x.AdditionalDefinitionID.toString() === additionalId);
            const definition = additionalDefinition?.AdditionalDefinitionItems.find(x => x.AdditionalDefinitionItemID.toString() === value);
            return <td>{definition?.Value}</td>;
        }
        default:
            return <td>{value}</td>;
    }
};

export default InvoiceTableItem;

